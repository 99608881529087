<template>
  <div class="livestock-panel">
    <LivestockHeader :gender="gender" :current-stock="currentStock" />
    <div class="livestock-panel-content">
      <div class="form-group">
        <div class="input-container">
          <NumericUpDown
            v-model="value.losses" integer
            :minimum="0"
            :up-icon="'fa fa-plus'" :down-icon="'fa fa-minus'"
          >
            <template #label>
              <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Losses</IxRes>
            </template>
          </NumericUpDown>
        </div>
      </div>
      <div class="form-group">
        <div class="input-container">
          <NumericUpDown
            v-model="value.legDamage" integer
            :minimum="0"
            :up-icon="'fa fa-plus'" :down-icon="'fa fa-minus'"
            inline
          >
            <template #label>
              <IxRes>Areas.PoultryManagement.SR_PoultryManagement.LegDamage</IxRes>
            </template>
          </NumericUpDown>
        </div>
      </div>
      <div class="form-group">
        <div class="input-container">
          <NumericUpDown
            v-model="value.apathy" integer
            :minimum="0"
            :up-icon="'fa fa-plus'" :down-icon="'fa fa-minus'"
          >
            <template #label>
              <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Apathy</IxRes>
            </template>
          </NumericUpDown>
        </div>
      </div>
      <div class="form-group">
        <div class="input-container">
          <NumericUpDown
            v-model="value.emaciation" integer
            :minimum="0"
            :up-icon="'fa fa-plus'" :down-icon="'fa fa-minus'"
          >
            <template #label>
              <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Emaciation</IxRes>
            </template>
          </NumericUpDown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import NumericUpDown from '../components/NumericUpDown'
import LivestockHeader from '../components/LivestockHeader'

export default {
  components: {
    NumericUpDown,
    LivestockHeader
  },
  extends: LivestockHeader,
  mixins: [
    StandardRulesMixin
  ],
  props: {
    value: {
      type: Object
    },
    currentStock: Number,
    gender: String
  },
  data () {
    return {}
  },
  watch: {
    losses (value) {
      this.$emit('update:value', value)
    },
    legDamage (value) {
      this.$emit('update:value', value)
    },
    emaciation (value) {
      this.$emit('update:value', value)
    },
    apathy (value) {
      this.$emit('update:value', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.livestock-panel {
  display: flex;
  flex-direction: column;
  width: 100%;

  .livestock-panel-content {
    padding: 0 40px 0 40px;
    display: flex;
    flex-direction: column;
  }
}
</style>
