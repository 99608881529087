<template>
  <div :class="isFullScreen">
    <div class="row">
      <div class="col-md-6">
        <FrsSearch v-model="searchString" :data="data" :keys="searchKeys" />
      </div>
    </div>
    <div class="gutter">
      <!-- XXX Bad hack to obfuscate the linter -->
    </div>
    <div class="row">
      <div class="col-md-12">
        <div v-for="house in poultryHouses" :key="house.id" class="content-container">
          <div class="row">
            <div class="col-md-12">
              <TmListCard
                class="item"
                :title="`Stall ${house.number} - Herdennummer ${getFlock(house.id).name}`"
                :status="getPoultryHouseVisitStatus(house.id)"
                :livestock="getPoultryHouseLivestock(house.id)"
                :selected="house.id === selectedPoultryHouseId"
                @selectItem="togglePoultryHouse(house.id)"
              >
                <div class="row">
                  <div class="col-md-4">
                    <IxButton
                      class="control-button"
                      large
                      next
                      @click="visitPoultryHouse(house.id, 1)"
                    >
                      <IxRes>Areas.PoultryManagement.SR_PoultryManagement.FirstControlRun</IxRes>
                      <IxRes>Common.SR_Common.Edit</IxRes>
                    </IxButton>
                    <PoultryHouseMenu v-if="house.id === selectedPoultryHouseId && selectedVisit === 1" />
                  </div>
                  <div class="col-md-4">
                    <IxButton
                      class="control-button"
                      large
                      next
                      @click="visitPoultryHouse(house.id, 2)"
                    >
                      <IxRes>Areas.PoultryManagement.SR_PoultryManagement.SecondControlRun</IxRes>

                      <IxRes>Common.SR_Common.Edit</IxRes>
                    </IxButton>
                    <PoultryHouseMenu v-if="house.id === selectedPoultryHouseId && selectedVisit === 2" />
                  </div>
                </div>
              </TmListCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import FrsSearch from '@components/search/Search'
import IxButton from '@components/IxButton'

import TmListCard from './widgets/TmListCard'
import PoultryHouseMenu from './widgets/PoultryHouseMenu'

export default {
  components: {
    FrsSearch,
    IxButton,
    TmListCard,
    PoultryHouseMenu
  },
  props: {
    fullScreen: Boolean
  },
  data () {
    return {
      searchString: '',
      searchKeys: ['', ''],
      data: ['']
    }
  },
  computed: {
    ...mapState('poultryManagementPrototype', {
      selectedPoultryHouseId: state => state.ui.selection.poultryHouse.id,
      selectedVisit: state => state.ui.selection.poultryHouse.visit,
      visits: state => state.data.poultryHouseVisits,
      selectedDate: state => state.ui.selection.date,
      poultryHouses: state => state.data.poultryHouses.filter(house => house.farmId === state.ui.selection.farmId),
      livestock: state => state.data.livestock
    }),
    ...mapGetters('poultryManagementPrototype', [
      'selectedPoultryHouse',
      'getFlock',
      'getPoultryHouseLivestock',
      'getPoultryHouseVisitStatus'
    ]),
    isFullScreen () {
      return this.fullScreen ? 'poultry-house-container-full-screen' : 'poultry-house-container'
    }
  },
  methods: {
    ...mapActions('poultryManagementPrototype', [
      'setSelectedPoultryHouseId',
      'setSelectedPoultryHouseVisit'
    ]),
    togglePoultryHouse (poultryHouseId) {
      const visit = this.selectedVisit ? this.selectedVisit : 1

      if (poultryHouseId === this.selectedPoultryHouseId) {
        this.setSelectedPoultryHouseId(null)
          .then(this.setSelectedPoultryHouseVisit(visit))
      } else {
        this.setSelectedPoultryHouseId(poultryHouseId)
          .then(this.setSelectedPoultryHouseVisit(visit))
      }
    },
    visitPoultryHouse (poultryHouseId, visit) {
      if (visit === this.selectedVisit && poultryHouseId === this.selectedPoultryHouseId) {
        this.setSelectedPoultryHouseId(null)
      } else if (poultryHouseId !== this.selectedPoultryHouseId) {
        this.setSelectedPoultryHouseId(poultryHouseId)
          .then(this.setSelectedPoultryHouseVisit(visit))
      } else {
        this.setSelectedPoultryHouseVisit(visit)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gutter {
  height: 15px;
}

.fa {
  align-self: center;
  font-size: 24px;
  color: green;
  padding-left: 8px;
  padding-top: 8px;
}
.item:hover {
  cursor: pointer;
}
.poultry-house-container-full-screen {
  width: 49%;
  padding: {
    left: 15px;
    right: 15px;
  }
}
.poultry-house-container {
  width: 100%;
  padding: {
             left: 15px;
             right: 15px;
           }
}
.control-button {
  height: 39px;
  margin-bottom: 5px;
}
</style>
