<template>
  <LabelAndMessage>
    <slot slot="label" name="label" />
    <div class="numeric-up-down">
      <slot name="left" />
      <button :disabled="downDisabled" class="btn btn-default numeric-button-down" @click="downButtonClicked">
        <i :class="downIcon" />
      </button>
      <NumericInput
        ref="base"
        :readonly="readonly || keyboardInputDisabled"
        :rule="rule" :integer="integer"
        :value="value" :name="name"
        centered hide-description
        @input="changeValue"
      />
      <button :disabled="upDisabled" class="btn btn-default numeric-button-up" @click="upButtonClicked">
        <i :class="upIcon" />
      </button>
      <slot name="right" />
    </div>
  </LabelAndMessage>
</template>

<script>
import NumericInput from '@components/forms/NumericInput'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'
// import FormPartMixin from '@components/forms/FormPartMixin'
// import InputMixin from '../../../components/forms/input-base/InputMixin'

export default {
  components: {
    NumericInput,
    LabelAndMessage
  },
  mixins: [
    //  FormPartMixin,
    //  InputMixin,
    StandardRulesMixin
  ],
  props: {
    value: Number,
    name: String,
    minimum: {
      type: Number,
      default: null
    },
    maximum: {
      type: Number,
      default: null
    },
    readonly: Boolean,
    keyboardInputDisabled: Boolean,
    integer: {
      type: Boolean,
      default: true
    },
    stepSize: {
      type: Number,
      default: 1
    },
    upIcon: {
      type: String,
      default: 'fa fa-angle-up'
    },
    downIcon: {
      type: String,
      default: 'fa fa-angle-down'
    }
  },
  data () {
    return {
      disabled: false
    }
  },
  computed: {
    rule () {
      if (this.minimum == null && this.maximum == null) {
        return null
      }

      if (this.minimum == null) {
        return this.max(this.maximum)
      }

      if (this.maximum == null) {
        return this.min(this.minimum)
      }

      return this.between(this.minimum, this.maximum)
    },
    upDisabled () {
      return this.readonly || (this.maximum != null && this.value + this.stepSize > this.maximum)
    },
    downDisabled () {
      return this.readonly || (this.minimum != null && this.value - this.stepSize < this.minimum)
    }
  },
  methods: {
    changeValue (newValue) {
      if (!this.rule || this.rule(newValue)) {
        this.$emit('input', newValue)
      }
    },
    upButtonClicked () {
      this.changeValue(this.value + this.stepSize)
    },
    downButtonClicked () {
      this.changeValue(this.value - this.stepSize)
    }
  }
}
</script>

<style lang="scss" scoped>
.numeric-up-down {
  display: flex;

  input {
    min-width: 40px;
  }

  .numeric-button-up {
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: none;
    margin-right: 2px;
  }
  .numeric-button-down {
    border-right: none;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    margin-left: 2px;
  }
}

</style>
