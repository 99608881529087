<template>
  <div>
    <div class="card" @click="$emit('selectItem')">
      <ListCard :selected="selected" :title="title" show-details>
        <div slot="header" class="total-animal-count">
          <div v-if="farm" class="count">
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Animals</IxRes>
          </div>
          <i class="fa fa-venus" />
          <div class="count">
            {{ formatNumber(livestock.female) }}
          </div>
          <i class="fa fa-mars" />
          <div class="count">
            {{ formatNumber(livestock.male) }}
          </div>
        </div>
        <template>
          <slot />
        </template>
      </ListCard>
      <div class="status" :class="status" />
    </div>
  </div>
</template>

<script>
import ListCard from '@components/card/ListCard'

export default {
  components: {
    ListCard
  },
  props: {
    farm: Boolean,
    title: String,
    livestock: {
      type: Object,
      default: () => ({male: 0, female: 0})
    },
    status: String,
    selected: Boolean
  },
  methods: {
    formatNumber (value) {
      return value >= 0 ? this.$i18n.format(value) : value
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}
.title {
  font-size: 1.4em;
}
.total-animal-count {
  padding-top: 3px;
  display: flex;
  flex-direction: row;
}
.status {
  width: 6px;
  margin: 0px 0px 5px 0px;
}
.in-progress {
  background-color: orange;
}
.finished {
  background-color: green;
}
.not-started {
  background-color: red;
}
i {
  text-align: center;
}
.fa {
  margin-left: 6px;
  padding-top: 4px;
  font-size: 1em;
}
.count {
  margin-left: 6px;
  font-size: 1.2em;
}
.panel {
  width: 100%;
}
</style>
