<template>
  <div class="automatic-panel">
    <div class="animal-count panel-row">
      <NumericUpDown v-model="value.count" up-icon="fa fa-plus" down-icon="fa fa-minus">
        <template #label>
          <IxRes>Areas.PoultryManagement.SR_PoultryManagement.AnimalCount</IxRes>
        </template>
      </NumericUpDown>
    </div>
    <div class="panel-row">
      <NumericUpDown v-model="value.averageWeight" up-icon="fa fa-plus" down-icon="fa fa-minus">
        <template #label>
          <IxRes>Areas.PoultryManagement.SR_PoultryManagement.AverageWeight</IxRes>
        </template>
      </NumericUpDown>
    </div>
    <div class="panel-row">
      <NumericUpDown v-model="value.uniformity" up-icon="fa fa-plus" down-icon="fa fa-minus">
        <template #label>
          <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Uniformity</IxRes>
        </template>
      </NumericUpDown>
    </div>
  </div>
</template>

<script>
import NumericUpDown from '../components/NumericUpDown'

export default {
  components: {
    NumericUpDown
  },
  props: {
    value: {},
    gender: {
      type: String,
      default: 'female'
    }
  }
}

</script>

<style lang="scss" scoped>
.automatic-panel {
  .panel-row {
    margin-bottom: 8px;
  }
}
</style>
