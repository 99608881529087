<template>
  <div class="header">
    <div class="header-item">
      <div v-if="showSettingsButtons" class="button-container">
        <IxButton large icon="cog" disabled>
          <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Settings</IxRes>
        </IxButton>
        <IxButton large icon="wrench" disabled>
          <IxRes>Areas.PoultryManagement.SR_PoultryManagement.ManageCompany</IxRes>
        </IxButton>
      </div>
      <div v-else class="back-button" @click="navigateToFarmSelection">
        <i class="fa fa-chevron-left" />
        <div class="back-text">
          <IxRes>Areas.PoultryManagement.SR_PoultryManagement.BackToFarmSelection</IxRes>
        </div>
      </div>
      <div class="info-container">
        <div v-if="selectedFarm" class="farm-name">
          <h2>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Farm</IxRes> {{ selectedFarm.name }}
          </h2>
        </div>
        <div v-if="selectedPoultryHouse" class="farm-name">
          <h2>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.PoultryHouse</IxRes>
            {{ selectedPoultryHouse.number }}
          </h2>
        </div>
        <input v-model="date" type="date">
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },
  props: {
    fullScreen: Boolean
  },
  computed: {
    ...mapGetters('poultryManagementPrototype', [
      'selectedFarm',
      'selectedPoultryHouse',
      'isInAdminComponent'
    ]),
    date: {
      get () {
        return this.$store.state.poultryManagementPrototype.ui.selection.date
      },
      set (value) {
        if (!value) return
        this.setSelectedDate(value)
      }
    },
    showSettingsButtons () {
      return ((this.fullScreen && this.selectedFarm) || (!this.fullScreen && !this.selectedFarm)) && !this.isInAdminComponent
    }
  },
  methods: {
    ...mapActions('poultryManagementPrototype', [
      'setSelectedDate',
      'navigateToFarmSelection'
    ])
  }
}
</script>

<style scoped>
.header-item {
  margin: 8px;
  display: flex;
  justify-content: space-between;
}
h2 {
  margin: 0;
}
.back-button, .login-container {
  display: flex;
  flex-direction: row;
}
.back-button:hover {
  cursor: pointer;
}
.fa {
  margin-top: 7px;
  font-size: 1.8em;
}
.back-text, .sign-in-text {
  margin: 5px 8px 6px 5px;
  font-size: 1.3em;
}
.info-container {
  width: 70%;
  display: flex;
  justify-content: flex-end;
}
.calendar-container {
  margin-right: 10%;
}
.farm-name {
  margin-right: 50px;
}
</style>
