<template>
  <button
    type="button" :disabled="finished"
    class="btn btn-default"
    :class="isFinished" @click.prevent="$emit('click')"
  >
    <span class="button-content-container">
      <span>
        <i v-if="isFrontIcon" class="fa" :class="[`fa-${frontIcon}`]" />
        <span cspas="button-content">
          <slot>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.{{ buttonLabel }}</IxRes>
          </slot>
        </span>
      </span>
      <i v-if="isBackIcon && finished" class="fa" :class="[`fa-${backIcon}`]" />
      <span v-if="isBackIcon && !finished">
        <i class="fa fa-arrow-circle-right" />
      </span>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    isFrontIcon: Boolean,
    frontIcon: String,
    isBackIcon: Boolean,
    backIcon: String,
    buttonLabel: String,
    finished: Boolean
  },
  computed: {
    isFinished () {
      return this.finished ? 'finished' : 'none'
    }
  }
}
</script>

<style scoped>
.button-content-container {
  display: flex;
  justify-content: space-between;
}
.finished {
  background-color: lightgreen;
}
</style>
