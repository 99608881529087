import Style from 'ol/style/Style'
import Circle from 'ol/style/Circle'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'

import {labelText} from '@helpers/openlayers/styles'

function createStyleInternal (text, strokeColor, fillColor) {
  const label = labelText()

  // make sure it'll be a string
  label.setText(`${text}`)

  const stroke = new Stroke({color: strokeColor, width: 2})
  const fill = new Fill({color: fillColor})

  return new Style({
    image: new Circle({
      radius: 5,
      stroke,
      fill
    }),
    stroke,
    fill,
    text: label
  })
}

export default {
  createFarmStyle (feature) {
    return createStyleInternal(feature.get('name'), 'rgb(129, 27, 184)', 'rgba(129, 27, 184, 0.2)')
  },
  createFarmSelectionStyle (feature) {
    return createStyleInternal(feature.get('name'), 'rgb(129, 27, 184)', 'rgba(129, 27, 184, 0.3)')
  },
  createFarmHoverStyle (feature) {
    return createStyleInternal(feature.get('name'), 'rgb(129, 27, 184)', 'rgba(255, 255, 255, 0.1)')
  },

  createPoultryHouseStyle (feature) {
    return createStyleInternal(feature.get('number'), 'rgb(129, 27, 184)', 'rgba(129, 27, 184, 0.2)')
  },
  createPoultryHouseSelectionStyle (feature) {
    return createStyleInternal(feature.get('number'), 'rgb(129, 27, 184)', 'rgba(129, 27, 184, 0.3)')
  },
  createPoultryHouseHoverStyle (feature) {
    return createStyleInternal(feature.get('number'), 'rgb(129, 27, 184)', 'rgba(255, 255, 255, 0.1)')
  }
}
