<template lang="html">
  <div>
    <LivestockHeader class="header" :gender="gender" :current-stock="currentStock" />
    <div class="panel-content">
      <label><IxRes>Areas.PoultryManagement.SR_PoultryManagement.ActualFeedingAmount</IxRes></label>
      <div class="feeding-target-current">
        <NumericInput
          v-model="feedAmountPerAnimal" class="feeding-target-current-input"
          addon="g/Tier" format="f2"
        />
        <NumericInput
          v-model="feedAmountPerDay" class="feeding-target-current-input"
          addon="kg/Tag" format="f2"
        />
      </div>

      <label><IxRes>Areas.PoultryManagement.SR_PoultryManagement.FeedingTargetToday</IxRes></label>
      <div class="feeding-target-row">
        <span>{{ targetFeedAmountPerAnimalToday }}kg/Tier</span>
        <span>{{ targetFeedAmountPerDayToday }}kg/Tag</span>
      </div>

      <label><IxRes>Areas.PoultryManagement.SR_PoultryManagement.FeedingTargetTomorrow</IxRes></label>
      <div class="feeding-target-row">
        <span>{{ targetFeedAmountPerDayTomorrow }}kg/Tier</span>
        <span>{{ targetFeedAmountPerAnimalTomorrow }}kg/Tag</span>
      </div>

      <div class="used-feed">
        <VueNativeSelect
          id="usedFeed"
          v-model="usedFeed"
          :items="feeds"
          :value-selector="item => item.id"
          :text-selector="item => item.name"
          name="usedFeed"
        >
          <template #label>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.FoodType</IxRes>
          </template>
        </VueNativeSelect>
      </div>
      <div class="silo-row">
        <VueNativeSelect
          id="silo"
          v-model="siloNumber"
          :items="removalsFromSilo"
          :value-selector="item => item"
          :text-selector="item => item.name"
          name="silo"
          class="silo-selectize"
        >
          <template #label>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Silo</IxRes>
          </template>
        </VueNativeSelect>
        <NumericInput
          v-model="siloLevel"
          percent percent-not-normalized
          class="silo-level"
        >
          <template #label>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.SiloLevel</IxRes>
          </template>
        </NumericInput>
      </div>

      <label><IxRes>Areas.PoultryManagement.SR_PoultryManagement.Water</IxRes></label>
      <div class="water">
        <NumericInput v-model="waterConsumption" class="water-per-day" addon="l/Tag" />
        <span>{{ waterPerAnimal }}l/Tier</span>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from 'lodash'
import {mapGetters, mapActions} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'
import VueNativeSelect from '../components/VueNativeSelect'

import LivestockHeader from '../components/LivestockHeader'

export default {
  components: {
    LivestockHeader,
    NumericInput,
    VueNativeSelect
  },
  props: {
    gender: String,
    currentStock: Number
  },
  data () {
    return {
      removalsFromSilo: []
    }
  },
  computed: {
    ...mapGetters('poultryManagementPrototype', [
      'feeds',
      'selectedPoultryHouseVisit'
    ]),
    modulePath () {
      return this.gender
    },
    ...mapFormFields('poultryManagementPrototype/foodConsumption/{modulePath}', [
      'actualFeedAmountPerAnimal',
      'actualFeedAmountPerDay',
      'targetFeedAmountPerAnimalToday',
      'targetFeedAmountPerDayToday',
      'targetFeedAmountPerDayTomorrow',
      'targetFeedAmountPerAnimalTomorrow',
      'usedFeedId',
      'siloNumber',
      'siloLevelPercent',
      'waterPerDay',
      'waterPerAnimal'
    ]),
    feedAmountPerAnimal: {
      get () {
        return this.actualFeedAmountPerAnimal
      },
      set (value) {
        this.actualFeedAmountPerAnimal = value
      }
    },
    feedAmountPerDay: {
      get () {
        return this.actualFeedAmountPerDay
      },
      set (value) {
        this.actualFeedAmountPerDay = value
      }
    },
    usedFeed: {
      get () {
        return this.usedFeedId
      },
      set (value) {
        this.usedFeedId = value
      }
    },
    siloLevel: {
      get () {
        return this.siloLevelPercent
      },
      set (value) {
        this.siloLevelPercent = value
      }
    },
    waterConsumption: {
      get () {
        return this.waterPerDay
      },
      set (value) {
        this.waterPerDay = value
      }
    }
  },
  methods: {
    ...mapActions('poultryManagementPrototype', [
      'updateFoodConsumptionState'
    ])
  },
  watch: {
    feedAmountPerAnimal (value) {
      this.feedAmountPerDay = value * this.currentStock / 1000
    },
    feedAmountPerDay: debounce(function (value) {
      let tmp = value / this.currentStock * 1000
      this.feedAmountPerAnimal = isNaN(tmp) ? 0 : tmp
    }, 1000)
  },
  mounted () {
    if (this.selectedPoultryHouseVisit && this.selectedPoultryHouseVisit.foodConsumptionRecord) {
      let data = this.selectedPoultryHouseVisit.foodConsumptionRecord[this.gender]
      this.feedAmountPerAnimal = data.actualFeedAmountPerAnimal
      this.feedAmountPerDay = data.actualFeedAmountPerDay
      this.usedFeed = data.usedFeedId
      this.siloLevel = data.siloLevelPercent
      this.waterConsumption = data.waterPerDay
    } else {
      this.updateFoodConsumptionState(true)
    }
  }
}
</script>

<style lang="scss" scoped>

.feeding-target-current, .feeding-target-row, .silo-row, .water {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.silo-selectize, .silo-level, .feeding-target-current-input, .water-per-day {
  width: 80%;
}

.used-feed {
  width: 40%;
}

.silo-row, .water {
  grid-gap: 10px;

  span {
    align-self: center;
  }
}

</style>
