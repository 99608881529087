<template lang="html">
  <div class="food-consumption-record">
    <div class="panels">
      <FoodConsumptionPanel class="panel-container-left" gender="female" :current-stock="currentStock.female" />
      <FoodConsumptionPanel class="panel-container-right" gender="male" :current-stock="currentStock.male" />
    </div>
    <div class="bottom-container">
      <div class="notes-container">
        <TextInput v-model="note" :multiline="true">
          <template #label>
            <IxRes>Common.SR_Common.Notes</IxRes>
          </template>
        </TextInput>
      </div>
      <div class="bottom-buttons">
        <IxButton
          class="save-button btn btn-ok" save
          large
          @click="save"
        />
        <IxButton
          class="cancel-button" cancel
          large
          @click="cancel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import TextInput from '@components/forms/TextInput'

import FoodConsumptionPanel from './FoodConsumptionPanel'

export default {
  components: {
    IxButton,
    TextInput,
    FoodConsumptionPanel
  },
  computed: {
    ...mapGetters('poultryManagementPrototype', [
      'getPoultryHouseLivestock'
    ]),
    ...mapFormFields('poultryManagementPrototype/foodConsumption', [
      'note'
    ]),
    currentStock () {
      return this.getPoultryHouseLivestock()
    }
  },
  methods: {
    ...mapActions('poultryManagementPrototype', [
      'savePoultryHouseVisitFoodConsumptionRecord',
      'navigateToPoultryHouseSelection'
    ]),
    save () {
      this.savePoultryHouseVisitFoodConsumptionRecord()
        .then(() => {
          this.navigateToPoultryHouseSelection(false)
        })
    },
    cancel () {
      this.navigateToPoultryHouseSelection(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.food-consumption-record {
  width: 80%;
  margin: 0 auto;
}

.panels {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.panel-container-left {
  padding-right: 10px;
}

.panel-container-right {
  border-left: 1px solid lightgray;
  padding-left: 10px;
}

.bottom-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.bottom-buttons {
  justify-self: end;
  align-self: end;
}
</style>
