import Map from './Map'
import FarmSelection from './FarmSelection'
import PoultryHouseSelection from './PoultryHouseSelection'

import PoultryHouseVisitLivestockRecord from './poultryhouse-visits/LivestockRecord'
import PoultryHouseVisitEggRecord from './poultryhouse-visits/EggRecord'
import PoultryHouseVisitFoodConsumptionRecord from './poultryhouse-visits/FoodConsumptionRecord'
import PoultryHouseVisitAnimalWeightRecord from './poultryhouse-visits/AnimalWeightRecord'

export default {
  Map,
  FarmSelection,
  PoultryHouseSelection,
  PoultryHouseVisitLivestockRecord,
  PoultryHouseVisitEggRecord,
  PoultryHouseVisitFoodConsumptionRecord,
  PoultryHouseVisitAnimalWeightRecord
}
