<template>
  <label-and-message>
    <slot slot="label" name="label" />

    <select
      :id="id"
      :value="selectedValue"
      :name="name"
      class="form-control"
      @change="onChange"
    >
      <option
        v-for="(item, index) of items"
        :key="index"
        :value="index"
      >
        {{ textSelector(item) }}
      </option>
    </select>
  </label-and-message>
</template>

<script>

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {

  components: {
    LabelAndMessage
  },

  props: {
    value: Object,
    id: String,
    name: String,
    items: {
      type: Array,
      default: () => []
    },
    valueSelector: {
      type: Function,
      default: () => item => item
    },
    textSelector: {
      type: Function,
      default: () => item => item
    }
  },

  computed: {
    selectedValue () {
      return this.items.indexOf(this.value)
    }
  },

  methods: {
    onChange (event) {
      this.$emit('input', this.items[event.target.value])
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
