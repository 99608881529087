<template>
  <div class="general-panel">
    <div class="panel-row">
      <label class="panel-label">
        <IxRes>Areas.PoultryManagement.SR_PoultryManagement.RecordedAt</IxRes>
      </label>
      <input v-model="value.recordedAt" type="time">
    </div>
    <div class="panel-row">
      <label class="panel-label">
        <IxRes>Areas.PoultryManagement.SR_PoultryManagement.CheckedConveyorsAt</IxRes>
      </label>
      <input v-model="value.checkedConveyorsAt" type="time">
    </div>
    <TextInput v-model="value.notes" :multiline="true">
      <template #label>
        <IxRes>Common.SR_Common.Notes</IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>
import TextInput from '@components/forms/TextInput'

export default {
  components: {
    TextInput
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      recordedAt: null,
      checkedConveyorsAt: null,
      notes: null
    }
  },
  watch: {
    recordedAt (value) {
      this.$emit('update:value', value)
    },
    checkedConveyorsAt (value) {
      this.$emit('update:value', value)
    },
    notes (value) {
      this.$emit('update:value', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-row {
  display: flex;
  margin-bottom: 5px;
  flex-flow: row;
}

.panel-label {
  width: 20%;
}
</style>
