<template>
  <div class="livestock-header">
    <h4 class="headline">
      <div>
        <i :class="gender == 'male' ? 'fa fa-mars' : 'fa fa-venus'" />
      </div>
      <div>
        <IxRes>Areas.PoultryManagement.SR_PoultryManagement.{{ gender == 'male' ? 'MaleAnimals' : 'FemaleAnimals' }}</IxRes>
        |
        <IxRes v-if="currentStock >= 0">Areas.PoultryManagement.SR_PoultryManagement.CurrentStock</IxRes> {{ formatNumber(currentStock) }}
      </div>
      <div>
        <slot />
      </div>
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    gender: {
      type: String,
      default: 'male'
    },
    currentStock: {
      type: Number,
      default: 0
    }
  },
  methods: {
    formatNumber (value) {
      return value >= 0 ? this.$i18n.format(value) : value
    }
  }
}
</script>

<style lang="scss" scoped>
.livestock-header {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  .headline {
    display: flex;
    flex-direction: row;

    div {
      padding: 4px;
    }
  }

  .current-stock {
    padding-left: 20px;
  }
}

</style>
