<template lang="html">
  <IxMap
    ref="map" :layers="[satLayer]"
    :extent="extent" :after-extent-changed="'zoom'"
  />
</template>

<script>
import {mapState, mapActions} from 'vuex'

import Group from 'ol/layer/Group'

import {makeDefaultLayerGroup} from '@helpers/openlayers/bing'
import {makeVectorLayer} from '@helpers/openlayers/layers'

import IxMap from '@components/map/IxMap'

import mapStyles from './map/map-styles'

export default {

  provide () {
    return {
      registerMap: () => this.$refs.map.map
    }
  },

  components: {
    IxMap
  },
  mixins: {

  },
  data () {
    return {
      satLayer: null,
      farmsLayer: null,
      poultryHousesLayer: null,

      farmSelectionPlugin: null,
      poultryHouseSelectionPlugin: null
    }
  },
  computed: {
    ...mapState('poultryManagementPrototype', {
      mapMode: state => state.ui.map.mode,

      displayMode: state => state.ui.displayMode,

      farmId: state => state.ui.selection.farmId,

      farms: state => state.data.farms,
      poultryHouses: state => state.data.poultryHouses
    }),

    extent () {
      if (this.activeLayer) {
        return this.activeLayer.getLayers().getArray()[0].getSource().getExtent()
      }

      return null
    },
    activeLayer () {
      if (this.mapMode === 'displayFarms') {
        return this.createFarmsLayer()
      } else {
        return this.createPoultryHousesLayer()
      }
    }
  },
  methods: {
    ...mapActions('poultryManagementPrototype', [
      'setSelectedFarmId',
      'setSelectedPoultryHouseId'
    ]),
    calcExtent () {
      if (this.canRender) {
        this.extent = this.activeLayer.getLayers().getArray()[0].getSource().getExtent()
      }
    },
    createFarmsLayer () {
      const features = this.farms.filter(farm => farm.feature).map(farm => farm.feature)

      const layer = makeVectorLayer({id: 'farms', name: 'Farmen', features, style: mapStyles.createFarmStyle})
      const group = new Group({id: 'farmsGroup', layers: [layer], visible: true})

      return group
    },
    createPoultryHousesLayer () {
      // don't need the other farms here
      const features = this.poultryHouses
        .filter(house => house.feature)
        .filter(house => house.farmId === this.farmId)
        .map(house => house.feature)

      const layer = makeVectorLayer({id: 'poultryHouses', name: 'Ställe', features, style: mapStyles.createPoultryHouseStyle})
      const group = new Group({id: 'poultryHousesGroup', layers: [layer], visible: true})

      return group
    }
  },
  watch: {
    displayMode () {
      this.$refs.map.fixViewport()
    }
  },
  beforeMount () {
    this.satLayer = makeDefaultLayerGroup(this.$store.state.i18n.locale)
  }
}

</script>
