<template lang="html">
  <div v-if="initComplete" class="poultry-management">
    <div class="header-container">
      <Header :full-screen="isFullScreen" />
    </div>
    <div class="main">
      <div
        ref="leftPanel"
        class="left-panel"
        :style="{flex: (leftPanelVisible ? 1 : 0),
                 display: (leftPanelVisible ? 'flex' : 'none')}"
      >
        <div class="panel-content-container">
          <component :is="leftPanelContent" ref="leftPanelContent" />
        </div>
      </div>
      <div
        ref="splitter"
        class="splitter"
        :style="{display: (splitterVisible ? 'block' : 'none')}"
      >
        <button @click="cycleDisplayMode">
          <i :class="(displayMode == 'rightPanelMaximized' ? 'fa fa-angle-right' : 'fa fa-angle-left')" />
        </button>
      </div>
      <div
        ref="rightPanel"
        class="right-panel"
        :style="{flex: (rightPanelVisible ? 1 : 0),
                 display: (rightPanelVisible ? 'flex' : 'none')}"
      >
        <div class="panel-content-container">
          <component :is="rightPanelContent" ref="rightPanelContent" :full-screen="isFullScreen" />
        </div>
      </div>
    </div>
    <TranslationControls />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

import TranslationControls from '@components/translation/TranslationControls'

import SelfDestruct from '@mixins/SelfDestruct'

import Header from './widgets/Header'
import Components from './component-registry'

export default {
  components: {
    ...Components,
    TranslationControls,
    Header
  },
  mixins: [
    SelfDestruct
  ],
  computed: {
    ...mapState('poultryManagementPrototype', {
      displayMode: state => state.ui.displayMode,
      leftPanelContent: state => state.ui.navigation.leftPanelContent,
      rightPanelContent: state => state.ui.navigation.rightPanelContent,
      initComplete: state => state.initComplete
    }),
    leftPanelVisible () {
      return !!this.leftPanelContent && this.displayMode !== 'rightPanelMaximized'
    },
    rightPanelVisible () {
      return !!this.rightPanelContent && this.displayMode !== 'leftPanelMaximized'
    },
    splitterVisible () {
      return !!this.leftPanelContent && !!this.rightPanelContent
    },
    isFullScreen () {
      return this.displayMode === 'rightPanelMaximized'
    }
  },
  methods: {
    ...mapActions('poultryManagementPrototype', [
      'loadPermissions',
      'loadFarms',
      'loadFeeds',
      'loadPoultryHouses',
      'loadFlocks',
      'loadMaterials',
      'loadPoultryHouseVisits',
      'loadLivestock',
      'navigateToFarmSelection'
    ]),
    ...mapMutations('poultryManagementPrototype', [
      'cycleDisplayMode',
      'setInitComplete'
    ])
  },
  created () {
    this.setInitComplete(false)
    Promise.all([
      this.$store.dispatch('i18n/preloadTranslations', [
        'Common.SR_Common',
        'Messages.Info.SR_InfoMessages',
        'Areas.PoultryManagement.SR_PoultryManagement'
      ]),
      this.loadPermissions(),
      this.loadFarms(),
      this.loadFeeds(),
      this.loadPoultryHouses(),
      this.loadFlocks(),
      this.loadMaterials(),
      this.loadPoultryHouseVisits(),
      this.loadLivestock()
    ])
      .then(() => this.navigateToFarmSelection())
      .then(() => this.setInitComplete(true))
  }
}
</script>

<style lang="scss" scoped>
.poultry-management {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header-container {
  width: 100%;
  height: 50px;
  display: block;
}

.main {
  position: relative;
  display: flex;
  width: calc(100% - 12px);
  flex: 1;
  z-index: 1;
}

.left-panel, .right-panel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  background: #fff;
  position: relative;
  height: calc(100% - 12px);
  margin-left: 6px;
  margin-right: 6px;
}

.panel-content-container {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.splitter {
  height: 100%;
  width: 20px;
  align-items: stretch;

  button {
    height: 100%;
  }
}

</style>
