<template>
  <div class="packaging-panel">
    <slot name="title" />
    <div v-for="position in value.positions" :key="position.packagingMaterial.id" class="data-row">
      <div class="dropdown-container">
        <VueNativeSelect
          id="packagingMaterial"
          v-model="position.packagingMaterial"
          :items="availablePackagingMaterials.concat(packagingMaterials.find(x => x.id === position.packagingMaterial.id))"
          :value-selector="item => item"
          :text-selector="item => item.name"
          name="packagingMaterial"
        >
          <template #label>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Packaging</IxRes>
          </template>
        </VueNativeSelect>
      </div>
      <div class="input-container">
        <NumericUpDown
          v-model="position.count" :minimum="0"
          :up-icon="'fa fa-plus'"
          :down-icon="'fa fa-minus'"
        >
          <template #label>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.PackageCount</IxRes>
          </template>
        </NumericUpDown>
      </div>
    </div>
    <div class="data-row">
      <div class="button-container">
        <IxButton add :disabled="packagingMaterials.length <= value.positions.length" @click="addPosition()" />
      </div>
    </div>
    <div class="data-row remainder">
      <div class="dropdown-container">
        <VueNativeSelect
          id="packagingMaterial"
          v-model="value.remainder.packagingMaterial"
          :items="eggPackagingMaterials"
          :value-selector="item => item"
          :text-selector="item => item.name"
          name="packagingMaterial"
        >
          <template #label>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.PackagingRemainder</IxRes>
          </template>
        </VueNativeSelect>
      </div>
      <div class="input-container">
        <NumericUpDown
          v-model="value.remainder.count" :minimum="0"
          :maximum="value.remainder.packagingMaterial.capacity"
          :up-icon="'fa fa-plus'" :down-icon="'fa fa-minus'"
        >
          <template #label>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.PackagingRemainderCount</IxRes>
          </template>
        </NumericUpDown>
      </div>
    </div>
    <hr>
    <div class="data-row total">
      <div class="total-container">
        <Total v-model="value">
          <template #label>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.TotalEggs</IxRes>
          </template>
        </Total>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'

import VueNativeSelect from '../components/VueNativeSelect'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import IxButton from '@components/IxButton'

import NumericUpDown from '../components/NumericUpDown'

import Total from './EggRecord.Total'

export default {
  components: {
    IxButton,
    NumericUpDown,
    VueNativeSelect,
    Total
  },
  mixins: [
    StandardRulesMixin
  ],
  props: {
    eggType: {
      type: String,
      default: 'BroodEgg'
    },
    value: Object
  },
  computed: {
    ...mapGetters('poultryManagementPrototype', [
      'getEggMaterial',
      'getMatchingPackagingMaterials'
    ]),
    eggMaterial () {
      return this.getEggMaterial(this.eggType)
    },
    packagingMaterials () {
      return this.getMatchingPackagingMaterials(this.eggType)
    },
    usedPackagingMaterials () {
      return this.packagingMaterials.filter(avm => this.value.positions.find(pos => avm.id === pos.packagingMaterial.id))
    },
    availablePackagingMaterials () {
      return this.packagingMaterials.filter(x => !this.usedPackagingMaterials.includes(x))
    },
    eggPackagingMaterials () {
      return this.packagingMaterials.filter(avm => avm.materialId === this.eggMaterial.id)
    }
  },
  methods: {
    addPosition () {
      const next = this.availablePackagingMaterials[0]
      this.value.positions.push({packagingMaterial: next, count: 0})
    }
  },
  beforeMount () {
    // init missing props
    if (!this.value) {
      this.$set(this, 'value', {})
    }

    if (!(this.value.remainder && this.value.remainder.packagingMaterial)) {
      const remainder = {packagingMaterial: this.packagingMaterials.find(pm => pm.materialId === this.eggMaterial.id), count: 0}
      this.$set(this.value, 'remainder', remainder)
    }

    if (!(this.value.positions && this.value.positions.length > 0)) {
      const positions = [{packagingMaterial: this.packagingMaterials[0], count: 0}]
      this.$set(this.value, 'positions', positions)
    }
  }
}
</script>
<style lang="scss" scoped>
.packaging-panel {
  .data-row {
    display: flex;
    flex-direction: row;

    .remainder {
      margin-top: 8px;
    }

    .total {
      .total-container {
        width: 200px;
      }
    }
  }
  .button-container {
    height: 33px;
  }
  .dropdown-container {
    margin-right: 4px;
    width: 200px;
  }
  .input-container {
    width: 240px;
  }
}
</style>
