<template>
  <div class="weight-record-container">
    <div class="panels">
      <div class="record-row">
        <div class="record-panel left">
          <div class="panel-row mode-selector">
            <IxButton large :class="{active: selectedMode === 'manual'}" @click="selectedMode = 'manual'">
              <IxRes>Areas.PoultryManagement.SR_PoultryManagement.ManualWeighing</IxRes>
            </IxButton>
            <IxButton large :class="{active: selectedMode === 'automatic'}" @click="selectedMode = 'automatic'">
              <IxRes>Areas.PoultryManagement.SR_PoultryManagement.AutomaticWeighing</IxRes>
            </IxButton>
          </div>
          <div class="panel-row results-container">
            <div class="panel-row">
              <ResultRow key="manualResults" :result="manualResult">
                <template #label>
                  <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Manual</IxRes>
                </template>
              </ResultRow>
              <ResultRow key="automaticResults" :result="automaticResult">
                <template #label>
                  <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Automatic</IxRes>
                </template>
              </ResultRow>
            </div>
          </div>
        </div>
        <div class="record-panel">
          <h4 class="panel-row headline">
            <IxRes v-if="selectedMode === 'manual'">Areas.PoultryManagement.SR_PoultryManagement.ManualWeighing</IxRes>
            <IxRes v-if="selectedMode === 'automatic'">Areas.PoultryManagement.SR_PoultryManagement.AutomaticWeighing</IxRes>
          </h4>
          <div class="panel-row gender-selector">
            <BsRadioList
              v-model="selectedGender"
              :options="[{label: 'Areas.PoultryManagement.SR_PoultryManagement.Female', value: 'female'}, {label: 'Areas.PoultryManagement.SR_PoultryManagement.Male', value: 'male'}]"
              horizontal
            />
          </div>
          <div v-if="selectedMode === 'manual'" class="panel-row">
            <ManualPanel
              v-if="selectedGender === 'female'"
              key="manFemale"
              v-model="record.manual.female"
              gender="female"
            />
            <ManualPanel
              v-if="selectedGender === 'male'"
              key="manMale"
              v-model="record.manual.male"
              gender="male"
            />
          </div>
          <div v-if="selectedMode === 'automatic'" class="panel-row">
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.AutomaticWeighing</IxRes>
            <AutomaticPanel
              v-if="selectedGender === 'female'"
              key="autoFemale"
              v-model="record.automatic.female"
              gender="female"
            />
            <AutomaticPanel
              v-if="selectedGender === 'male'"
              key="autoMale"
              v-model="record.automatic.male"
              gender="male"
            />
          </div>
        </div>
      </div>
      <div class="notes-container panel-row">
        <TextInput v-model="record.notes" :multiline="true">
          <template #label>
            <IxRes>Common.SR_Common.Notes</IxRes>
          </template>
        </TextInput>
      </div>
      <div class="footer">
        <IxButton cancel large @click="cancel" />
        <IxButton save large @click="save" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

import {mapResources} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import TextInput from '@components/forms/TextInput'
import BsRadioList from '@components/bootstrap/BsRadioList'

import AutomaticPanel from './AnimalWeightRecord.AutomaticPanel'
import ManualPanel from './AnimalWeightRecord.ManualPanel'

import ResultRow from './AnimalWeightRecord.ResultRow'

function getDefaultRecord () {
  return {
    automatic: {
      female: {
        count: 50,
        averageWeight: 0,
        uniformity: 0
      },
      male: {
        count: 50,
        averageWeight: 0,
        uniformity: 0
      }
    },
    manual: {
      female: {
        count: 50,
        details: []
      },
      male: {
        count: 50,
        details: []
      }
    }
  }
}

export default {
  components: {
    IxButton,
    TextInput,
    BsRadioList,
    AutomaticPanel,
    ManualPanel,
    ResultRow
  },

  data () {
    return {
      selectedMode: 'manual', // 'automatic'
      selectedGender: 'female', // 'male'
      editLast: false,
      record: getDefaultRecord()
    }
  },

  computed: {
    ...mapResources([
      '@pm.SR_PoultryManagement'
    ]),
    ...mapState('poultryManagementPrototype', {
      date: state => state.ui.selection.date,
      poultryHouseId: state => state.ui.selection.poultryHouse.id,
      visit: state => state.ui.selection.poultryHouse.visit
    }),
    ...mapGetters('poultryManagementPrototype', [
      'selectedPoultryHouseVisit'
    ]),
    manualResult () {
      return {
        male: {
          count: this.record.manual.male.count,
          averageWeight: this.calcAverage(this.record.manual.male.details),
          uniformity: this.calcUniformity(this.record.manual.male.details)
        },
        female: {
          count: this.record.manual.female.count,
          averageWeight: this.calcAverage(this.record.manual.female.details),
          uniformity: this.calcUniformity(this.record.manual.female.details)
        }
      }
    },
    automaticResult () {
      return {
        male: this.record.automatic.male,
        female: this.record.automatic.female
      }
    }
  },

  methods: {
    ...mapActions('poultryManagementPrototype', [
      'navigateToPoultryHouseSelection',
      'savePoultryHouseVisitAnimalWeightRecord'
    ]),
    calcAverage (details) {
      let sum = 0
      details.forEach(w => {
        sum += w
      })
      return sum / details.length
    },
    calcUniformity (details) {
      var avg = this.calcAverage(details)
      var maxAboveAvg = avg * 1.10
      var minBelowAvg = avg * 0.9

      const uniform = details.filter(w => w >= minBelowAvg && w <= maxAboveAvg)
      const uniformity = (uniform.length / details.length) * 100

      return uniformity
    },
    save () {
      const record = {...this.record}

      // strip unused props - or just leave it as is, defaults should work however
      if (record.automatic.male.averageWeight === 0) {
        delete record.automatic.male
      }
      if (record.automatic.female.averageWeight === 0) {
        delete record.automatic.female
      }
      if (record.automatic === {}) {
        delete record.automatic
      }

      const avgMale = this.calcAverage(this.record.manual.male.details)
      const avgFemale = this.calcAverage(this.record.manual.female.details)

      if (isNaN(avgMale) || avgMale <= 0) {
        delete record.manual.male
      }
      if (isNaN(avgFemale) || avgFemale <= 0) {
        delete record.manual.female
      }
      if (record.manual === {}) {
        delete record.manual
      }

      this.savePoultryHouseVisitAnimalWeightRecord({
        date: this.date,
        poultryHouseId: this.poultryHouseId,
        visit: this.visit,
        animalWeightRecord: record
      })
      this.navigateToPoultryHouseSelection(false)
    },
    cancel () {
      this.navigateToPoultryHouseSelection(false)
    }
  },
  beforeMount () {
    const visit = this.selectedPoultryHouseVisit
    let record = null

    const defaults = this.record

    if (visit && visit.animalWeightRecord) {
      record = {...visit.animalWeightRecord}

      // fill in defaults if needed
      if (!record.automatic) {
        record.automatic = defaults.automatic
        // this.$set(record, 'automatic', defaults.automatic)
      } else {
        if (!record.automatic.male) {
          // this.$set(record, 'automatic.male', defaults.automatic.male)
          record.automatic.male = defaults.automatic.male
        }
        if (!record.automatic.female) {
          // this.$set(record, 'automatic.female', defaults.automatic.female)
          record.automatic.female = defaults.automatic.female
        }
      }

      if (!record.manual) {
        record.manual = defaults.manual
      } else {
        if (!record.manual.male) {
          record.manual.male = defaults.manual.male
        }
        if (!record.manual.female) {
          record.manual.female = defaults.manual.female
        }
      }
    } else {
      record = defaults
    }

    this.$set(this.$data, 'record', record)
  }
}

</script>
<style lang="scss" scoped>
.weight-record-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  .panels {
    flex-direction: column;
    min-width: 50%;

    .panel-row {
      margin: 4px;
    }
  }

  .mode-selector {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    button {
      display: block;
      margin: 4px;
    }
  }

  .record-row {
    flex-direction: row;
    display: flex;
  }

  .record-panel {
    width: calc(50% - 4px);
    flex-direction: column;

    &.left {
      margin-right: 8px;
      border-right: solid 1px gray;
    }
  }

  .results-container {
    flex-direction: column;
  }

  .inputs-panel {
    align-content: left;
    flex-direction: column;
  }

  .notes-container {
    width: 100%;
    align-content: center;
    flex-direction: column;
  }

  .footer {
    display: flex;
    flex-flow: row-reverse;
    flex-direction: row-reverse;

    button {
      margin-left: 4px;
    }
  }
}

</style>
