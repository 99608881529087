<template>
  <div class="manual-panel">
    <div class="animal-count panel-row">
      <NumericUpDown
        v-model="value.count"
        :readonly="isCountInitialized"
        :minimum="1"
        up-icon="fa fa-plus"
        down-icon="fa fa-minus"
      >
        <template #label>
          <IxRes>Areas.PoultryManagement.SR_PoultryManagement.AnimalCount</IxRes>
        </template>
        <IxButton
          slot="right"
          icon="check"
          class="btn btn-success"
          :disabled="isCountInitialized"
          large
          @click="isCountInitialized = true"
        />
      </NumericUpDown>
    </div>
    <div v-if="isCountInitialized" class="steps">
      <div class="progress-container panel-row">
        <div class="progress-label">
          <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Progress</IxRes> {{ `${currentIndex + 1} / ${value.count}` }}
        </div>
        <div class="progress">
          <div class="progress-bar" :style="{width: `${(currentIndex + 1) / value.count * 100 }%`}" />
        </div>
      </div>
      <div class="step panel-row">
        <div class="panel-row">
          <NumericUpDown
            v-model="value.details[currentIndex]"
            :readonly="isEditingLast || currentIndex === value.count - 1"
            up-icon="fa fa-plus"
            down-icon="fa fa-minus"
          >
            <template #label>
              <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Weight</IxRes>
            </template>
            <IxButton slot="right" :disabled="isEditingLast || !canEditNext" @click="editNext">
              <IxRes>Areas.PoultryManagement.SR_PoultryManagement.EditNextWeight</IxRes>
            </IxButton>
          </NumericUpDown>
        </div>
        <div v-if="canEditLast" class="panel-row">
          <IxRes>Areas.PoultryManagement.SR_PoultryManagement.LastAnimal</IxRes>
          {{ value.details[currentIndex - 1] }} g
          <IxButton :disabled="isEditingLast || !canEditLast" @click="editLast">
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.EditLastWeight</IxRes>
          </IxButton>
        </div>
      </div>
    </div>
    <div v-if="canEditLast && isEditingLast" class="last panel-row">
      <div>
        <NumericUpDown v-model="lastTemp" up-icon="fa fa-plus" down-icon="fa fa-minus">
          <template #label>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Weight</IxRes>
          </template>
          <IxButton
            slot="left"
            icon="times"
            class="btn btn-danger"
            large
            @click="cancelEditingLast"
          />
          <IxButton
            slot="right"
            icon="check"
            class="btn btn-success"
            large
            @click="changeLast"
          />
        </NumericUpDown>
      </div>
    </div>
  </div>
</template>

<script>
import IxButton from '@components/IxButton'

import NumericUpDown from '../components/NumericUpDown'

export default {
  components: {
    IxButton,
    NumericUpDown
  },

  props: {
    gender: {
      type: String,
      default: 'female'
    },
    value: {
      type: Object,
      default: () => ({
        count: 50,
        uniformity: 100,
        details: [0]
      })
    }
  },
  data () {
    return {
      isCountInitialized: false,
      isEditingLast: false,

      lastTemp: 0,

      currentIndex: 0
    }
  },
  computed: {
    canEditLast () {
      return this.currentIndex > 0
    },
    canEditNext () {
      return this.currentIndex < this.value.count - 1
    }
  },
  methods: {
    editNext () {
      this.isEditingLast = false // maybe add a confirmation dialog?
      this.value.details.push(this.value.details[this.currentIndex])
      this.currentIndex++
    },
    editLast () {
      // todo: fadeout the current step
      this.lastTemp = this.value.details[this.currentIndex]
      this.isEditingLast = true
    },
    cancelEditingLast () {
      this.isEditingLast = false
    },
    changeLast () {
      this.value.details[this.currentIndex - 1] = this.lastTemp
      this.isEditingLast = false
    }
  },
  beforeMount () {
    if (this.value.details.length < 1) {
      this.value.details = [0]
      this.currentIndex = 0
      this.isCountInitialized = false
    } else if (this.value.details.length === this.value.count) {
      // it's the last, so just overflow the index
      this.currentIndex = this.value.details.length - 1
      // this.currentTemp = this.value.details[currentIndex]
      this.isCountInitialized = true
    } else {
      this.currentIndex = this.value.details.length - 1
      this.isCountInitialized = true
    }
  },

  beforeDestroy () {
    if (!this.isCountInitialized) {
      this.value.details = []
    }
  }
}
</script>

<style lang="scss" scoped>

.manual-panel {

  .panel-row {
    margin-bottom: 8px;
  }

  .steps {
    display: flex;
    flex-direction: column;
  }
  .progress-container {
    width: 100%;
    display: block;
  }
}

</style>
