<template>
  <div :class="isFullScreen">
    <div class="header-container">
      <div class="searchbar-container">
        <FrsSearch v-model="searchString" :data="data" :keys="searchKeys" />
      </div>
      <div class="logo-container">
        <object class="wimex-logo" data="../../../../images/branding/Wimex/wimex.png" type="image/png">
          Ihr Browser kann leider kein png darstellen.
        </object>
      </div>
    </div>
    <div v-for="farm in farms" :key="farm.id" class="content-container">
      <TmListCard
        class="farm-item"
        farm
        :selected="farm.id === selectedFarmId"
        :title="farm.name"
        :livestock="getFarmLivestock(farm.id)"
        :status="getFarmVisitStatus(farm.id)"
        @selectItem="showPoultryHouses(farm.id)"
      />
      <i class="fa fa-map-marker" />
      <i class="fa fa-bar-chart" />
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import FrsSearch from '@components/search/Search'
import TmListCard from './widgets/TmListCard'

export default {
  components: {
    FrsSearch,
    TmListCard
  },
  props: {
    fullScreen: Boolean
  },
  data () {
    return {
      searchString: '',
      searchKeys: ['', ''],
      data: ['']
    }
  },
  computed: {
    ...mapState('poultryManagementPrototype', {
      selectedFarmId: (state) => state.ui.selection.farmId,
      farms: (state) => state.data.farms
    }),
    ...mapGetters('poultryManagementPrototype', [
      'getFarmLivestock',
      'getFarmVisitStatus'
    ]),
    isFullScreen () {
      return this.fullScreen ? 'farm-selection-container-full-screen' : 'farm-selection-container'
    }
  },
  methods: {
    ...mapActions('poultryManagementPrototype', [
      'setSelectedFarmId',
      'navigateToPoultryHouseSelection'
    ]),
    showPoultryHouses (farmId) {
      this.setSelectedFarmId(farmId)
    }
  },
  watch: {
    selectedFarmId () {
      this.navigateToPoultryHouseSelection()
    }
  }
}
</script>

<style scoped>
.farm-selection-container {
  width: 100%;
}
.farm-selection-container-full-screen {
  width: 49%;
}
.header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.wimex-logo {
  width: 60px;
  height: 60px;
}
.content-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.fa {
  margin-left: 4px;
  padding-top: 8px;
  width: 40px;
  height: 40px;
  font-size: 1.6em;
  border: 1px solid lightgrey;
  background-color: white;
}
.fa:hover {
  cursor: pointer;
  border-color: #a0d9ef;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.075);
}
.fa-map-marker {
  padding-left: 12px;
}
.fa-bar-chart {
  padding-left: 7px;
}
.farm-item:hover {
  cursor: pointer;
}
.farm-item {
  width: 50%;
}
</style>
