<template>
  <div class="panel-row">
    <label>
      <slot name="label" />
    </label>
    <p>
      <i class="fa fa-venus" />
      {{ formatNumber(result.female.averageWeight) }} ({{ result.female.count }}
      <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Animals</IxRes>)
      <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Uniformity</IxRes> {{ formatNumber(result.female.uniformity) }} %
    </p>
    <p>
      <i class="fa fa-mars" />
      {{ formatNumber(result.male.averageWeight) }} ({{ result.male.count }}
      <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Animals</IxRes>)
      <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Uniformity</IxRes> {{ formatNumber(result.male.uniformity) }} %
    </p>
  </div>
</template>
<script>

export default {
  props: {
    result: {
      type: Object,
      default: () => ({
        male: {count: 0, averageWeight: 0, uniformity: 100},
        female: {count: 0, averageWeight: 0, uniformity: 100}
      })
    }
  },
  methods: {
    formatNumber (value) {
      return value >= 0 ? this.$i18n.format(value) : value
    }
  }
}

</script>
