<template>
  <div class="menu-container">
    <MenuButton
      class="menu-item" is-back-icon
      back-icon="check-square"
      button-label="Livestock" @click="openLivestockRecord"
    />
    <MenuButton
      class="menu-item" is-back-icon
      back-icon="check-square"
      button-label="Eggs" @click="openEggRecord"
    />
    <MenuButton
      class="menu-item" is-back-icon
      back-icon="check-square"
      button-label="FoodConsumption" @click="openFoodConsumptionRecord"
    />
    <MenuButton
      class="menu-item" is-back-icon
      back-icon="check-square"
      button-label="AnimalWeight" @click="openAnimalWeightRecord"
    />
    <MenuButton
      class="menu-item" is-front-icon
      front-icon="bar-chart"
      button-label="Evaluation"
      disabled
    />
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import MenuButton from './MenuButton'

export default {
  components: {
    MenuButton
  },
  methods: {
    ...mapActions('poultryManagementPrototype', [
      'setContent',
      'updateFoodConsumptionState'
    ]),
    openLivestockRecord () {
      this.setContent('PoultryHouseVisitLivestockRecord')
    },
    openEggRecord () {
      this.setContent('PoultryHouseVisitEggRecord')
    },
    openFoodConsumptionRecord () {
      this.setContent('PoultryHouseVisitFoodConsumptionRecord')
      this.updateFoodConsumptionState(false)
    },
    openAnimalWeightRecord () {
      this.setContent('PoultryHouseVisitAnimalWeightRecord')
    }
  }
}
</script>

<style scoped>
.menu-container {
  margin-left: 30px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
}
.menu-item {
  margin: 2px;
}
</style>
