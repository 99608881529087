<template>
  <NumericInput readonly hide-description :value="total">
    <slot slot="label" name="label" />
  </NumericInput>
</template>
<script>
import NumericInput from '@components/forms/NumericInput'

export default {
  components: {
    NumericInput
  },
  props: {
    value: Object
  },
  computed: {
    total () {
      let total = 0
      if (this.value) {
        if (this.value.positions) {
          this.value.positions.forEach(p => {
            total += (p.packagingMaterial.baseTypeCapacity * p.count)
          })
        }
        if (this.value.remainder) {
          total += this.value.remainder.count
        }
      }

      return isNaN(total) ? 0 : total
    }
  }
}
</script>
