<template>
  <div class="livestock-record">
    <div class="panels">
      <div class="female-panel">
        <LivestockPanel v-model="record.female" :gender="'female'" :current-stock="currentStock.female" />
      </div>
      <div class="male-panel">
        <LivestockPanel v-model="record.male" :gender="'male'" :current-stock="currentStock.male" />
      </div>
    </div>
    <div v-if="!finalStockIsPositiv" class="panel panel-danger">
      <div class="panel-body">
        <em class="center">
          Die Abg&auml;nge sind gr&ouml;&szlig;als der Bestand, bitte pr&uuml;fen!
        </em>
      </div>
    </div>
    <div class="bottom-container">
      <div class="notes">
        <TextInput v-model="record.notes" multiline>
          <template #label>
            <IxRes>Common.SR_Common.Notes</IxRes>
          </template>
        </TextInput>
      </div>
      <div class="bottom-buttons">
        <IxButton
          :disabled="!finalStockIsPositiv"
          class="save-button btn btn-ok" save
          large
          @click="save"
        >
          <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Save</IxRes>
        </IxButton>
        <IxButton
          class="cancel-button" cancel
          large
          @click="cancel"
        >
          <IxRes>Areas.PoultryManagement.SR_PoultryManagement.Cancel</IxRes>
        </IxButton>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapState, mapGetters} from 'vuex'

import TextInput from '@components/forms/TextInput'

import IxButton from '@components/IxButton'

import LivestockPanel from './LivestockPanel'

function getDefaultLivestock () {
  return {
    currentStock: 0,
    losses: 0,
    legDamage: 0,
    emaciation: 0,
    apathy: 0
  }
}

function getDefaultData () {
  return {
    male: getDefaultLivestock(),
    female: getDefaultLivestock(),
    notes: ''
  }
}

export default {
  components: {
    TextInput,
    IxButton,
    LivestockPanel
  },
  data () {
    return {
      record: getDefaultData()
    }
  },
  computed: {
    ...mapState('poultryManagementPrototype', {
      date: state => state.ui.selection.date,
      poultryHouseId: state => state.ui.selection.poultryHouse.id,
      visit: state => state.ui.selection.poultryHouse.visit
    }),
    ...mapGetters('poultryManagementPrototype', [
      'selectedPoultryHouseVisit',
      'getPoultryHouseLivestock'
    ]),
    currentStock () {
      return this.getPoultryHouseLivestock()
    },
    finalStockIsPositiv () {
      const maleStockIsPositiv = this.currentStock.male -
        this.record.male.losses -
        this.record.male.legDamage -
        this.record.male.emaciation -
        this.record.male.apathy
      const femaleStockIsPositiv = this.currentStock.female -
        this.record.female.losses -
        this.record.female.legDamage -
        this.record.female.emaciation -
        this.record.female.apathy
      return maleStockIsPositiv >= 0 && femaleStockIsPositiv >= 0
    }
  },
  methods: {
    ...mapActions('poultryManagementPrototype', [
      'navigateToPoultryHouseSelection',
      'savePoultryHouseVisitLivestockRecord'
    ]),
    updateNotes (value) {
      this.$emit('update:notes', value)
    },
    save () {
      this.savePoultryHouseVisitLivestockRecord({
        date: this.date,
        poultryHouseId: this.poultryHouseId,
        visit: this.visit,
        livestockRecord: this.record
      })
      this.navigateToPoultryHouseSelection(false)
    },
    cancel () {
      this.navigateToPoultryHouseSelection(false)
    }
  },
  beforeMount () {
    const visit = this.selectedPoultryHouseVisit
    let record = null

    if (visit && visit.livestockRecord) {
      record = {...visit.livestockRecord}
    } else {
      record = getDefaultData()
    }

    this.$set(this.$data, 'record', record)
  }
}

</script>
<style lang="scss" scoped>

.livestock-record {
  width: 80%;
  margin: 0 auto;
}

.panels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
}

.male-panel {
  border-left: 1px solid lightgray;
}

.bottom-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 10px;
}

.bottom-buttons {
  justify-self: end;
  align-self: end;
}

</style>
