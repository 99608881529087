<template>
  <div class="egg-record">
    <div class="panels">
      <div class="panel-container left">
        <div class="eggs-panel">
          <div class="panel-row">
            <Total :value="record.brood">
              <template #label>
                <IxRes>Areas.PoultryManagement.SR_PoultryManagement.BroodEggs</IxRes>
              </template>
            </Total>
            <IxButton v-if="activePanel == 'brood'" :icon="'chevron-left'" @click="togglePanel('brood')">
              <IxRes>Common.SR_Common.Close</IxRes>
            </IxButton>
            <IxButton v-else edit @click="togglePanel('brood')" />
          </div>
          <div class="panel-row">
            <Total :value="record.consumer.small">
              <template #label>
                <IxRes>Areas.PoultryManagement.SR_PoultryManagement.SmallConsumerEggs</IxRes>
              </template>
            </Total>
            <IxButton v-if="activePanel == 'consumer-small'" :icon="'chevron-left'" @click="togglePanel('consumer-small')">
              <IxRes>Common.SR_Common.Close</IxRes>
            </IxButton>
            <IxButton v-else edit @click="togglePanel('consumer-small')" />
          </div>
          <div class="panel-row">
            <Total :value="record.consumer.big">
              <template #label>
                <IxRes>Areas.PoultryManagement.SR_PoultryManagement.BigConsumerEggs</IxRes>
              </template>
            </Total>
            <IxButton v-if="activePanel == 'consumer-big'" :icon="'chevron-left'" @click="togglePanel('consumer-big')">
              <IxRes>Common.SR_Common.Close</IxRes>
            </IxButton>
            <IxButton v-else edit @click="togglePanel('consumer-big')" />
          </div>
          <div class="panel-row">
            <NumericUpDown
              v-model="record.dirty" :up-icon="'fa fa-plus'"
              :down-icon="'fa fa-minus'"
              :minimum="0" required
            >
              <template #label>
                <IxRes>Areas.PoultryManagement.SR_PoultryManagement.DirtyEggs</IxRes>
              </template>
            </NumericUpDown>
          </div>
          <div class="panel-row">
            <NumericUpDown
              v-model="record.floored" :up-icon="'fa fa-plus'"
              :down-icon="'fa fa-minus'"
              :minimum="0" required
            >
              <template #label>
                <IxRes>Areas.PoultryManagement.SR_PoultryManagement.FlooredEggs</IxRes>
              </template>
            </NumericUpDown>
          </div>
          <div class="panel-row">
            <NumericUpDown
              v-model="record.destroyed" :up-icon="'fa fa-plus'"
              :down-icon="'fa fa-minus'"
              :minimum="0" required
            >
              <template #label>
                <IxRes>Areas.PoultryManagement.SR_PoultryManagement.DestroyedEggs</IxRes>
              </template>
            </NumericUpDown>
          </div>
        </div>
      </div>
      <div class="panel-container">
        <GeneralPanel v-if="activePanel == 'general'" key="general" v-model="record.general" />
        <PackagingPanel
          v-else-if="activePanel == 'brood'" key="brood"
          v-model="record.brood"
          :egg-type="'BroodEgg'" @input="$forceUpdate()"
        >
          <template #title>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.BroodEggs</IxRes>
          </template>
        </PackagingPanel>
        <PackagingPanel
          v-else-if="activePanel == 'consumer-small'" key="consumer-small"
          v-model="record.consumer.small"
          :egg-type="'ConsumerEgg'" @input="$forceUpdate()"
        >
          <template #title>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.SmallConsumerEggs</IxRes>
          </template>
        </PackagingPanel>
        <PackagingPanel
          v-else-if="activePanel == 'consumer-big'" key="consumer-big"
          v-model="record.consumer.big"
          :egg-type="'ConsumerEgg'" @input="$forceUpdate()"
        >
          <template #title>
            <IxRes>Areas.PoultryManagement.SR_PoultryManagement.BigConsumerEggs</IxRes>
          </template>
        </PackagingPanel>
      </div>
    </div>

    <div class="bottom-buttons">
      <IxButton
        class="cancel-button" cancel
        large
        @click="cancel"
      />
      <IxButton
        class="save-button btn btn-ok" save
        large
        @click="save"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import {mapActions, mapState, mapGetters} from 'vuex'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import IxButton from '@components/IxButton'
import NumericUpDown from '../components/NumericUpDown'
import GeneralPanel from './EggRecord.GeneralPanel'
import PackagingPanel from './EggRecord.PackagingPanel'
import Total from './EggRecord.Total'

function getDefaultRecord () {
  const now = moment().format('LT')

  return {
    brood: {
      positions: [],
      remainder: Object
    },
    consumer: {
      small: {
        positions: [],
        remainder: Object
      },
      big: {
        positions: [],
        remainder: Object
      }
    },
    dirty: 0,
    floored: 0,
    destroyed: 0,

    general: {
      // title: 'general',
      recordedAt: now,
      checkedConveyorsAt: now,
      notes: null
    }
  }
}

export default {
  components: {
    IxButton,
    NumericUpDown,
    GeneralPanel,
    PackagingPanel,
    Total
  },
  mixins: [
    StandardRulesMixin
  ],
  data () {
    return {
      record: getDefaultRecord(),
      activePanel: 'general'
    }
  },
  computed: {
    ...mapState('poultryManagementPrototype', {
      date: state => state.ui.selection.date,
      poultryHouseId: state => state.ui.selection.poultryHouse.id,
      visit: state => state.ui.selection.poultryHouse.visit
    }),
    ...mapGetters('poultryManagementPrototype', [
      'selectedPoultryHouseVisit'
    ])
  },
  methods: {
    ...mapActions('poultryManagementPrototype', [
      'navigateToPoultryHouseSelection',
      'savePoultryHouseVisitEggRecord'
    ]),
    togglePanel (name) {
      if (this.activePanel === name) {
        this.activePanel = 'general'
      } else {
        this.activePanel = name
      }
    },
    save () {
      const stripPackagingDetails = (detail) => {
        let remainder = null

        if (detail.remainder && detail.remainder.count > 0) {
          // normally, we only need the ids
          remainder = {
            packagingMaterial: detail.remainder.packagingMaterial,
            count: detail.remainder.count
          }
        }

        let positions = detail.positions.filter(pos => pos.count > 0)

        if (positions.length > 0) {
          // normally, we only need the ids
          positions = positions.map(pos => {
            return {packagingMaterial: pos.packagingMaterial, count: pos.count}
          })
        }

        return {remainder, positions}
      }

      const record = {
        date: this.date,
        poultryHouseId: this.poultryHouseId,
        visit: this.visit,
        eggRecord: {
          brood: stripPackagingDetails(this.record.brood),
          consumer: {
            small: stripPackagingDetails(this.record.consumer.small),
            big: stripPackagingDetails(this.record.consumer.big)
          },
          dirty: this.record.dirty,
          floored: this.record.floored,
          destroyed: this.record.destroyed,

          general: {
            recordedAt: this.record.general.recordedAt,
            checkedConveyorsAt: this.record.general.checkedConveyorsAt,
            notes: this.record.general.notes
          }
        }
      }

      this.savePoultryHouseVisitEggRecord(record)
      this.navigateToPoultryHouseSelection(false)
    },
    cancel () {
      this.navigateToPoultryHouseSelection(false)
    }
  },
  beforeMount () {
    const visit = this.selectedPoultryHouseVisit
    let record = null

    if (visit && visit.eggRecord) {
      record = visit.eggRecord
    } else {
      record = getDefaultRecord()
    }

    this.activePanel = 'general'
    this.$set(this.$data, 'record', record)
  }
}
</script>

<style lang="scss" scoped>
.egg-record {
    display: flex;
    flex-direction: column;
    padding: 4px;
    width: 80%;
    height: 100%;
    margin: 0 auto;
  }

  .panels {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    .panel-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;

      > button {
        margin-left: 4px;
        height: 34px;
        margin-top: 25px;
      }
    }

    .panel-container {
      padding: 12px;
      width: calc(50% - 24px);

      &.left {
        border-right: 1px solid lightgray;
      }
    }
  }
  .bottom-buttons {
    padding: 0 38px 0 0;
    display: flex;
    flex-flow: row-reverse;

    button {
      margin: 4px;
    }
  }
</style>
